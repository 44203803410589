import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ShoppingCart } from '@vizsla/components';
import { useExperienceRegistrationByIdQuery, useExperienceLandingQuery } from '@vizsla/graphql';
import { useNotification } from '@vizsla/hooks';

import { SupportPageLayout } from 'src/layouts';
import { PurchaseDescriptorHeader } from 'src/components';
import { useSelectedExperience } from 'src/hooks';

import { Container } from './ExperiencePurchaseLayout.styles';

export const ExperiencePurchaseLayout: React.FC<{ isDonation?: boolean }> = props => {
  const { experienceId } = useParams();
  const { data: experience, setData, setLoading } = useSelectedExperience();
  const { error: showError } = useNotification();
  const { data: experienceData, loading: experienceLoading } = useExperienceLandingQuery({
    variables: { id: experienceId as string },
    fetchPolicy: 'cache-first',
  });
  const { data, loading } = useExperienceRegistrationByIdQuery({
    skip: !experienceId,
    variables: { id: experienceId! },

    onCompleted(data) {
      if (!data.experience) {
        showError(`No experience found with the given ID`);
        return;
      }

      setData(data.experience);
    },

    onError(err) {
      showError(err.message);
    },
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const getTexts = useCallback(() => {
    if (props.isDonation) {
      return {
        subtitle: 'You are donating to',
        title:
          experienceData?.experience?.fundraisingSettings?.donationSettings
            ?.benefittingCharitiesOrganizationName ??
          experienceData?.experience?.campaign?.vizslaOrganization?.name ??
          experience?.name ??
          undefined,
      };
    }
    return {
      subtitle: `You are ${
        data?.experience?.experienceType === 'Ticketing' ? 'purchasing tickets' : 'registering'
      } for`,
      title: experience?.name ?? undefined,
    };
  }, [experience, experienceData, data, props.isDonation]);

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content>
        <Container>
          <PurchaseDescriptorHeader
            loading={loading || experienceLoading}
            subtitle={getTexts().subtitle}
            title={getTexts().title}
          />
          {props.children}
        </Container>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart experience={experience} showDiscount={!!experience} />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
};
